<template>
  <moe-page title="优惠券列表">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">优惠券</div>
            <div class="font-14">可以按需圈选商品范围，设置不同优惠力度。优惠券以电子券为载体，满足商家多样化营销诉求，提升店铺转化率。</div>
          </div>
          <div>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/coupon/add', { type: 'ALL' })">新增店铺券</el-button>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/coupon/add', { type: 'PART' })">新增商品券</el-button>
          </div>
        </div>
      </template>

      <!-- 筛选信息 -->
      <moe-inquire @search="couponSearch">
        <el-form-item label="活动状态">
          <moe-select type="couponState" v-model="couponParams.state" placeholder="选择活动状态"></moe-select>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model.trim="couponParams.name" placeholder="输入活动名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="券编号">
          <el-input :value="couponParams.id" @input="(value) => couponParams.id = $moe_formatter.formatterId(value)" placeholder="输入券编号" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="使用时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([couponParams.startTime, couponParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-inquire>

      <moe-tabs type="border-card" :tabsList="[{ label: '店铺券' }, { label: '商品券' }]" @change="handleChange">
        <template #0>
          <moe-table v-if="tabsIndex === 0" ref="couponTableRef" url="/shop/coupon/list" :params="couponParams" :number-show="false" :mode="mode">
            <el-table-column label="活动信息" min-width="150">
              <template slot-scope="{ row }">
                <div>{{ row.name }}</div>
                <div>券编号:{{ row.id }}</div>
              </template>
            </el-table-column>

            <el-table-column label="优惠方式" min-width="150" :formatter="formatterMethod" />
            <el-table-column label="发放量" min-width="100" prop="number" />
            <el-table-column label="领取量" min-width="100" prop="received" />
            <el-table-column label="使用量" min-width="100" prop="used" />

            <el-table-column label="使用时间" min-width="310">
              <div class="df fdc aic jcc" slot-scope="{ row }">
                <template v-if="row.timeType === 'TIME_RANGE'">
                  <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
                  <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
                </template>
                <div v-if="row.timeType === 'RELATIVE'">{{ `领取后 ${row.effectiveDays} 天有效` }}</div>
              </div>
            </el-table-column>

            <el-table-column label="状态" min-width="150">
              <div :class="$moe_format.getCouponStateColor(row.state)" slot-scope="{ row }">
                {{ $moe_format.getCouponState(row.state) }}
              </div>
            </el-table-column>

            <el-table-column label="操作" fixed="right" width="450">
              <div class="moe-table_btns" slot-scope="{ row }">
                <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/coupon/detail`, { type: 'ALL', id: row.id })">查看活动</el-button>
                <template v-if="!['CLOSED'].includes(row.state)">
                  <el-button type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/coupon/add`, { type: 'ALL', id: row.id })">编辑活动</el-button>
                  <el-button type="danger" size="mini" icon="el-icon-circle-close" @click="handleEnd(row)">结束</el-button>
                </template>
                <el-button v-if="['RECEIVING'].includes(row.state)" size="mini" icon="el-icon-thumb" @click="handleSend(row)">赠送优惠券</el-button>
              </div>
            </el-table-column>
          </moe-table>
        </template>

        <template #1>
          <moe-table v-if="tabsIndex === 1" ref="couponTableRef" url="/shop/coupon/list" :params="couponParams" :number-show="false" :mode="mode">
            <el-table-column label="活动信息" min-width="150">
              <template slot-scope="{ row }">
                <div>{{ row.name }}</div>
                <div>券编号:{{ row.id }}</div>
              </template>
            </el-table-column>

            <el-table-column label="优惠方式" min-width="150" :formatter="formatterMethod" />
            <el-table-column label="发放量" min-width="100" prop="number" />
            <el-table-column label="领取量" min-width="100" prop="received" />
            <el-table-column label="使用量" min-width="100" prop="used" />

            <el-table-column label="使用时间" min-width="310">
              <div class="df fdc aic jcc" slot-scope="{ row }">
                <template v-if="row.timeType === 'TIME_RANGE'">
                  <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
                  <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
                </template>
                <div v-if="row.timeType === 'RELATIVE'">{{ `领取后 ${row.effectiveDays} 天有效` }}</div>
              </div>
            </el-table-column>

            <el-table-column label="状态" min-width="150">
              <div :class="$moe_format.getCouponStateColor(row.state)" slot-scope="{ row }">
                {{ $moe_format.getCouponState(row.state) }}
              </div>
            </el-table-column>

            <el-table-column label="操作" fixed="right" width="450">
              <div class="moe-table_btns" slot-scope="{ row }">
                <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/coupon/detail`, { type: 'PART', id: row.id })">查看活动</el-button>
                <template v-if="!['CLOSED'].includes(row.state)">
                  <el-button type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/coupon/add`, { type: 'PART', id: row.id })">编辑活动</el-button>
                  <el-button type="danger" size="mini" icon="el-icon-circle-close" @click="handleEnd(row)">结束</el-button>
                </template>
                <el-button v-if="['RECEIVING'].includes(row.state)" size="mini" icon="el-icon-thumb" @click="handleSend(row)">赠送优惠券</el-button>
              </div>
            </el-table-column>
          </moe-table>
        </template>
      </moe-tabs>
    </moe-card>

    <userDialog title="赠送优惠券" :showDialog.sync="showDialog" @close="handleClose" @cancel="handleCancel" :showTableList="couponSendParams.target === 'SPECIFY_USER'">
      <template slot="tool">
        <moe-form
          class="default-form"
          ref="couponSendForm"
          :showBack="false"
          :model="couponSendParams"
          :rules="rules"
          :showTool="false">
          <el-form-item label="赠送数量" prop="number">
            <el-input class="w-200" :value="couponSendParams.number" placeholder="请输入数量" maxLength="6" @input="(value) => couponSendParams.number = $moe_formatter.formatterInteger(value)"></el-input>
          </el-form-item>
          <el-form-item label="优惠金额" v-if="couponItem.id">
            {{ `满${couponItem.condition}减${couponItem.discount}` }}
          </el-form-item>
          <el-form-item label="赠送对象" prop="target">
            <moe-select class="w-200" type="userTargetList" v-model="couponSendParams.target" placeholder="请选择赠送对象"></moe-select>
          </el-form-item>
          <el-form-item label="用户标签" prop="tagIds" v-if="couponSendParams.target === 'SPECIFY_TAG'">
            <el-select class="w-300" v-model="couponSendParams.tagIds" multiple filterable clearable placeholder="请选择用户标签">
              <el-option v-for="(item, index) in userTagList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </moe-form>
      </template>
    </userDialog>
  </moe-page>
</template>

<script>
import userDialog from '@/views/UserManage/components/dialog.vue'
export default {
  name: 'ActivityManageCouponList',
  components: {
    userDialog
  },
  data() {
    const verify = this.$moe_verify.verifyForm
    return {
      levelOptions: {
        0: 'ALL',
        1: 'PART',
      },
      tabsIndex: 0,
      mode: false,
      couponParams: {
        pageNum: 1,
        pageSize: 10,
        level: 'ALL',
        state: '',
        name: '',
        id: '',
        startTime: '',
        endTime: '',
      },
      datetime: [],
      showDialog: false,
      couponItem: {},
      couponSendParams: {
        number: '',
        userIds: [],
        target: '',
        tagIds: [],
      },
      userTagList: [],
      rules: {
        number: verify.isEmpty('请输入赠送数量'),
        target: verify.isEmpty('请选择赠送对象'),
        tagIds: verify.isEmpty('请选择赠送对象'),
      },
    }
  },
  methods: {
    formatterMethod({ condition, discount }) {
      return `满 ${condition} 元 减 ${discount} 元`
    },
    couponSearch(isSearch) {
      this.mode = true;
      if (!isSearch) {
        this.couponParams = {
          pageNum: 1,
          pageSize: 10,
          level: this.levelOptions[this.tabsIndex],
          state: '',
          name: '',
          id: '',
          startTime: '',
          endTime: '',
        }
        this.datetime = [];
      }

      this.$refs['couponTableRef'].searchData();
    },
    handleChange({ index }) {
      this.mode = true;
      this.tabsIndex = Number(index);
      this.couponParams.level = this.levelOptions[this.tabsIndex];
      this.couponParams.pageNum = 1;
      this.couponParams.pageSize = 10;
    },
    handleSend(item) {
      this.couponItem = item;
      this.showDialog = true;
    },
    handleCancel() {
      this.$refs['couponSendForm'].resetFields();
    },
    async handleClose(selectList) {
      this.$refs['couponSendForm'].validate(async () => {
        const { number, target, userIds, tagIds } = this.couponSendParams;
        this.couponSendParams.userIds = selectList;
        if (target === 'SPECIFY_USER' && !this.couponSendParams.userIds.length) {
          return this.$moe_msg.warning('请选择用户!');
        }
        this.$confirm(`您确定赠送"${this.couponSendParams.number}"张优惠券给选中"${target === 'SPECIFY_TAG' ? '标签' : ''}用户"吗？`, '系统提示', {
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: async(action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = instance.cancelButtonLoading = true;
              instance.confirmButtonText = instance.cancelButtonText = '请稍候...';
              const data = await this.$moe_api.COUPON_API.couponSend({
                id: this.couponItem.id,
                number,
                target,
                userIds: target === 'SPECIFY_USER' ? userIds.map(({ id }) => id) : [],
                tagIds: target === 'SPECIFY_TAG' ? tagIds : []
              })
              if (data?.code === 200) {
                this.$moe_msg.success('赠送优惠券成功', {
                  completionHandler: () => {
                    this.couponSendParams.userIds.splice(0);
                    this.handleCancel();
                    this.showDialog = false;
                  }
                });
              } else {
                if (data?.message) {
                  this.$moe_msg.error(data.message);
                }
              }
              instance.confirmButtonLoading = instance.cancelButtonLoading = false;
              instance.confirmButtonText = '确定';
              instance.cancelButtonText = '取消';
              done();
            } else if (action === 'cancel') {
              done();
            }
          }
        })
      })
    },
    /** 结束优惠券 */
    handleEnd({ id, name }) {
      this.$moe_layer.confirm(`您确定要结束当前选项"${name}"吗？`, () => {
        this.$moe_api.COUPON_API.endCoupon({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('结束成功');
            this.$refs['couponTableRef'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },
    /** 获取所有用户标签 */
    async req_getUserTagListAll() {
      const { code, result, message } = await this.$moe_api.USER_API.getUserTagListAll()
      if (code === 200) {
        this.userTagList = result.list.map(({ name, id }) => {
          return {
            label: name,
            value: id
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    }
  },
  mounted() {
    this.couponSearch(false);
    this.req_getUserTagListAll();
  }
}
</script>

<style lang="scss">

</style>